<template>
	<div class="card mt-4">
		<div class="card-header">
			<div class=" text-center">
				<div><h3>RECEIPT & PAYMENT</h3></div>
				<!-- <div>
					<date-range-picker
    v-model="dateRange"
	@update="dateChange"
	:opens="opens"
            :locale-data="{ format: 'dd-mm-yyyy' }"
  ></date-range-picker>
			</div> -->
			<div class="row">
					<div class="col-md-4"></div>
					<div class="col-md-4 text-center">
						<date-range-picker
    v-model="dateRange"
    @update="dateChange"
	:locale-data="{ format: 'dd-mm-yyyy' }"
  ></date-range-picker>
					</div>
					<div class="col-md-4 text-right">
  <button  type="button" class="btn btn-venuecolor"><i class="mdi mdi-export"></i>Export</button>

			</div>
			</div>
		</div>
			
		</div>
		<div class="card-body">
			<table class="table table-bordered table-sm">
				<thead class="thead-light">
					<tr >
						<td colspan="2" class="text-center border-right w-50">Receipt</td>
						<td colspan="2" class="text-center">Payment</td>
					</tr>
				</thead>
				<tr>
					<td class="text-left">Particular</td>
					<td class="border-left">Amount</td>
					<td class="text-left">Particular</td>
					<td>Amount</td>
				</tr>
				<tbody style="    height: 300px; ">
					<tr>
						<!--  Receipt  -->
					<!-- 	<td class="text-left wc-5" style="vertical-align: top;">
							<br>
							<br>
							<br><br>
                            <span v-for="(da ,i ) in income_list" :key="i">{{ date_format(da.date) }}<br></span>
						</td> -->
						<!-- <td class="text-left" style="vertical-align: top;">
							<b>Opening Balance </b><br>
							<div class="ml-4">  {{ account_no }} </div>
							<div class="ml-4"> Inhand cash  </div><br>
						</td> -->
						<!-- <td class="border-right text-right wc-5" style="vertical-align: top;">
							<br>
							0 <br>
							0 <br><br>
                            <span v-for="(da ,i ) in income_list" :key="i">
											<span v-html="currency_check(numberWithCommas(da.price))"></span>
											<br></span>

						</td> -->

						<td class="text-left w-40" style="vertical-align: top;">
							<b>Opening Balance </b><br>
							<div class="ml-4">  {{ bank_name }} </div>
							<div class="ml-4"> Cash Inhand  </div><br>
                                        <span v-for="(da ,i ) in income_list" :key="i">
											<b v-if="da.group.other.amount==0">{{ da.group.group }}</b>
											<div class="ml-2"  v-if="da.group.other.amount!=0">{{ da.group.group }}</div>
											<div class="ml-2"  v-if="da.group.Base_price.amount!=0">Base Price</div>
											<div class="ml-2"  v-if="da.group.Addon.amount!=0">Addon</div>
											<div class="ml-2"  v-if="da.group.Security_deposit.amount!=0">Security Deposit</div> 
										</span>
									
										<div class="text-danger mt-3" v-if="message1!=''" >{{ message1 }}<br></div>
									</td>
									<td class=" text-right wc-5  border-right" style="vertical-align: top;">
										<br>
										<div class="ml-4">  0 </div>
							<div class="ml-4"> 0  </div><br>
                                        <span v-for="(da ,i ) in income_list" :key="i">
											<b v-if="da.group.other.amount==0"><br></b>
											<div class="ml-2"  v-if="da.group.other.amount!=0"><span v-html="currency_check(da.group.other.amount)"></span></div>
											<div class="ml-2" v-if="da.group.Base_price.amount!=0"><span v-html="currency_check(da.group.Base_price.amount)"></span></div>
											<div class="ml-2" v-if="da.group.Addon.amount!=0"><span v-html="currency_check(da.group.Addon.amount)"></span></div>
											<div class="ml-2" v-if="da.group.Security_deposit.amount!=0"><span v-html="currency_check(da.group.Security_deposit.amount)"></span></div> 
										
										
										</span>
											
											
									
                                        <div class="text-danger  mt-3 " v-if="message1!=''" >
											<span v-html="currency_check(numberWithCommas(amount1))"></span>
											<br></div>
									</td> 

                        <!--  Payment  -->

					<!-- 	<td class="text-left wc-5" style="vertical-align: top;">
							<span v-for="(exp ,i ) in expense_list" :key="i">{{ date_format(exp.date) }}<br></span>
						</td> -->
						<td class="text-left w-40" style="vertical-align: top;">
                            <span v-for="(exp ,i ) in expense_list" :key="i">{{ exp.description }} <br></span>
							<div class="mb-fixed">
								<b>Closing Balance </b><br>
								<div class="ml-3">  {{ bank_name }} </div>
								<div class="ml-3"> Cash Inhand </div>
							</div>
						</td>
						<td class=" text-right wc-5" style="vertical-align: top;">
							<span v-for="(exp ,i ) in expense_list" :key="i">
											<span v-html="currency_check(numberWithCommas(exp.price))"></span>
											<br></span>

							<div class="mb-fixed">
								<br>
								<div class="ml-3"> 
                                    <span :class="[closing_bank>=0 ? 'text-success' : 'text-danger']" v-html="currency_check(numberWithCommas(closing_bank))"></span>
                                   
                                </div>
								<div class="ml-3"> 
                                    <span :class="[closing_cash>=0 ? 'text-success' : 'text-danger']" v-html="currency_check(numberWithCommas(closing_cash))"></span>
                                </div>
							</div>
						</td>
					</tr>
				</tbody>
				<tfoot class="thead-light">
					<tr>
						<th class="text-center">Total</th>
						<th  class="text-right border-right" >
							<span v-html="currency_check(numberWithCommas(income_sum))"></span>
							</th>

						<th class="text-center">Total</th>
						<th  class="text-right">
							<span v-html="currency_check(numberWithCommas(income_sum))"></span>
							</th>

						
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import helpers from '../../../mixins/helpers';

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
	export default {
		mixins: [helpers],
		data:()=>({
			income_list:[],
			expense_list:[],
			booking_prices:[],
			message:"",
			message1:"",
			amount:"",
			amount1:"",
			curncy:"",
			bank_name:"",
			account_no:"",
			closing_bank:0,
			closing_cash:0,
			income_sum:0,
expense_sum:0,
		dateRange: {
			startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
}),
components: {DateRangePicker},
		methods:
		{
			income_expense_list() {
				axios.post("/api/receipt_payment_report",{month:this.dateRange}).then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.income_list=resp.data.array_income;
							this.expense_list=resp.data.expense_list;
							this.booking_prices=resp.data.booking_price;
							this.closing_bank=resp.data.closing_bank;
							this.closing_cash=resp.data.closing_cash;
							this.income_sum=resp.data.income_sum;
							this.expense_sum=resp.data.expense_sum;
						
							
						
						}
					}
				});
			},
			currency_check(amt) {
				return '<i class="mdi '+this.curncy+'"></i>'+amt;
			},
			getProfile() {
				axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.curncy= resp.data.users.currencys;
						this.bank_name= resp.data.users.bank_name;
						this.account_no= resp.data.users.account_no;
					}
				});
			},
			dateChange()
			{
				this.income_expense_list();
			}
		},
		mounted()
		{
			this.getProfile();
			this.income_expense_list();
		}	
	}
</script>

<style>
	.mb-fixed
	{
    margin-top: 30px;
	}
    .wc-5
{
	width: 150px;
}
.w-40
{
	width: 38%;
}
</style>

1572